import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, Container, HeaderText, SubHeaderText } from '../../styles/globalStyle';
import { Steps } from 'antd';
import { fetchOrderDetails } from '../../action/parcel';
import { CheckCircleOutlined, CheckOutlined, CheckSquareOutlined, CloseCircleOutlined, WarningOutlined } from '@ant-design/icons';
const { Step } = Steps;
const ParcelDetails = ({location,fetchOrderDetails}) => {
    window.scrollTo(0, 0);
    const [lan, setLan] = useState('english');
    const [data, setData] = useState(null);
    useEffect( async () => {
      setLan(localStorage.getItem('lan') ? localStorage.getItem('lan') : 'english')
      const search = location.search;
      const shop_id = new URLSearchParams(search).get("shop_id");
      const order_id = new URLSearchParams(search).get("order_id");
      const mobile_number = new URLSearchParams(search).get("mobile_number");
      const res = await fetchOrderDetails(shop_id,order_id,mobile_number)
      setData(res)
    }, []);
    
  const makeLocalTime = (date) => {
    let mainDate = date.split('T')[0];
    let hours = date.split('T')[1].split(':')[0];
    let minutes = date.split('T')[1].split(':')[1];
    var am = true;
    if (hours > 12) {
        am = false;
        hours -= 12
    } else if (hours == 12) {
        am = false;
    } else if (hours == 0) {
        hours = 12
    }
    return mainDate + " , " + hours+":"+minutes + " " + (am ? "a.m" : "p.m")
  }
    return ( 
        <Container name="detail">
            <Row gutter={32}>
                <Col xs={24}>
                    <HeaderText>{lan === 'bangla' ? 'পার্সেলের ডিটেইলস' : 'Parcel Details'}</HeaderText>
                </Col>

                <Col xs={24} sm={8}>
                    <Card>
                        <SubHeaderText>{lan === 'bangla' ? 'কাষ্টমারের ইনফরমেশন' : 'Customer Information'}</SubHeaderText>
                        
                        <Row gutter={16}>
                            <Col xs={12} ><p>{lan === 'bangla' ? 'নাম': 'Name:'}</p></Col><Col xs={12} >{data?.recipient_name}</Col>
                            <Col xs={12} ><p>{lan === 'bangla' ? 'মোবাইল': 'Mobile:'}</p></Col><Col xs={12} >{data?.recipient_mobile_number}</Col>
                            <Col xs={12} ><p>{lan === 'bangla' ? 'ঠিকানা': 'Addrss:'}</p></Col><Col xs={12} >{data?.recipient_address}</Col>
                        </Row>
                        
                        
                    </Card>
                </Col>

                <Col xs={24} sm={8}>
                    <Card>
                        <SubHeaderText>{lan === 'bangla' ? 'চার্জ এর ইনফরমেশন' : 'Charge Information'}</SubHeaderText>
                        <Row gutter={16}>
                            <Col xs={12} ><p>{lan === 'bangla' ? 'সংগ্রহিত টাকা': 'Amount to Collect:'}</p></Col><Col xs={12} style={{color: 'green'}} >&nbsp;{data?.charges?.amount_to_collect?.toFixed(2)}</Col>
                            <Col xs={12} ><p>{lan === 'bangla' ? 'সিওডি চার্জ': 'COD Charge:'}</p></Col><Col xs={12} style={{color: 'red'}}>-{data?.charges?.cod?.toFixed(2)}</Col>
                            <Col xs={12} ><p>{lan === 'bangla' ? 'ডেলিভারি চার্জ': 'Delivery Charge:'}</p></Col><Col xs={12} style={{color: 'red'}}>-{data?.charges?.delivery_charge?.toFixed(2)}</Col>
                            {/* <Col xs={12} ><p>{lan === 'bangla' ? 'এক্সট্রা ওয়েট চার্জ': 'Extra Weight Charge:'}</p></Col><Col xs={12} >{data?.charges?.recipient_name}</Col> */}
                            <Col xs={12} ><p>{lan === 'bangla' ? 'অন্যান্য চার্জ': 'Additional Charge:'}</p></Col><Col xs={12} style={{color: 'red'}}>-{data?.charges?.additional_cost?.toFixed(2)}</Col>
                            <Col xs={24} ><hr /></Col>
                            <Col xs={12} ><p>{lan === 'bangla' ? 'পে-এবল চার্জ': 'Payable Amount:'}</p></Col><Col xs={12} style={{fontWeight: '600'}}>{data?.charges?.payable_amount?.toFixed(2)}</Col>
                        </Row>
                    </Card>
                </Col>

                <Col xs={24} sm={8}>
                    <Card>
                        <SubHeaderText>{lan === 'bangla' ? 'পার্সেল এর ইনফরমেশন' : 'Parcel Information'}</SubHeaderText>
                        <Row gutter={16}>
                            <Col xs={12} ><p>{lan === 'bangla' ? 'কাস্টমার এর ইনভয়েস আইডি' : 'Customer Invoice Id'}</p></Col><Col xs={12} >{data?.merchant_order_id}</Col>
                            <Col xs={12} ><p>{lan === 'bangla' ? 'পার্সেল এর আসল মুল্য': 'Parcel Actual Price:'}</p></Col><Col xs={12} >{data?.charges?.item_actual_price}</Col>
                            <Col xs={12} ><p>{lan === 'bangla' ? 'কোয়ান্টিটি': 'Quantity:'}</p></Col><Col xs={12} >{data?.quantity}</Col>
                            <Col xs={12} ><p>{lan === 'bangla' ? 'ওজন': 'Weight:'}</p></Col><Col xs={12} >{data?.weight} gm</Col>
                            <Col xs={12} ><p>{lan === 'bangla' ? 'বিস্তারিত': 'Item Description:'}</p></Col><Col xs={12} >{data?.description}</Col>
                            <Col xs={12} ><p>{lan === 'bangla' ? 'নোট': 'Note:'}</p></Col><Col xs={12} >{data?.note}</Col>
                        </Row>
                    </Card>
                </Col>

                <Col xs={24} sm={12}>
                    <Card>
                        <SubHeaderText>{lan === 'bangla' ? 'স্টোর এর ইনফরমেশন' : 'Shop Information'}</SubHeaderText>
                        <Row gutter={16}>
                            <Col xs={12} ><p>{lan === 'bangla' ? 'আইডি': 'ID :'}</p></Col><Col xs={12} >{data?.shop?.shop_id}</Col>
                            <Col xs={12} ><p>{lan === 'bangla' ? 'নাম': 'Name :'}</p></Col><Col xs={12} >{data?.shop?.name}</Col>
                            <Col xs={12} ><p>{lan === 'bangla' ? 'মোবাইল নাম্বার': 'Mobile Number :'}</p></Col><Col xs={12} >{data?.shop?.mobile_number}</Col>
                            <Col xs={12} ><p>{lan === 'bangla' ? 'ঠিকানা': 'Address :'}</p></Col><Col xs={12} >{data?.shop?.address}</Col>
                        </Row>
                    </Card>
                </Col>

                <Col xs={24} sm={12}>
                    <Card>
                        <SubHeaderText>{lan === 'bangla' ? 'ট্রেকিং এর ইনফরমেশন' : 'Tracking Details'}</SubHeaderText>
                            <div style={{alignSelf: 'center'}}>
                                <Steps direction="vertical"  >
                                    {data?.logs?.map((item, key) => (

                                        <Step title={item?.order_state} 
                                        responsive={true}
                                        status={
                                            item?.order_state === 'Canceled' ?
                                             "error" :
                                            item?.order_state === 'Delivered' ?
                                             "finish":
                                             "process"} 
                                        description={<><p>{item?.message}</p><p>{item?.created_at ? makeLocalTime(item?.created_at) : null}</p></>} 
                                        icon={
                                            item?.order_state === 'Canceled' ?
                                            <CloseCircleOutlined /> :
                                            item?.order_state === 'Delivered' ?
                                            <CheckCircleOutlined style={{color: '#2a850e'}} />:
                                            item?.order_state === 'Partially Delivered' ?
                                            <CheckSquareOutlined style={{color: '#68e043'}} />:
                                            item?.order_state === 'On Hold (Warehouse)' ?
                                            <WarningOutlined style={{color: '#f1c40f'}} />:
                                            item?.order_state === 'On Hold (Driver)' ?
                                            <WarningOutlined style={{color: '#f1c40f'}}  />:
                                            <CheckOutlined />}
                                        />

                                    ))}
                                    {/* <Step title="In Progress" description="This is a description." />
                                    <Step title="Waiting" description="This is a description." /> */}
                                </Steps>
                            </div>
                    </Card>
                </Col>

            </Row>

        </Container>
     );
}
 
export default connect(null, {fetchOrderDetails})(ParcelDetails);