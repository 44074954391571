import { Button, Result } from 'antd';
import React from 'react'
import { FlexContainer } from '../styles/globalStyle';
import history from '../history';
const MaintenancePage = () => {
    return ( 
        <FlexContainer>
            <Result
                icon={<img src='/images/maintanence.gif' style={{width: '250px'}} />}
                title="502"
                subTitle="Sorry, our server is under maintenance. We are getting back soon"
                extra={<Button onClick={()=> history.push('/') } type="primary">Back Home</Button>}
                style={{alignSelf: 'center'}}
            />
        </FlexContainer>
     );
}
 
export default MaintenancePage;