import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  Select,
  Space,
  Divider,
  Empty,
} from "antd";
import moment from "moment";
import './style.css'
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getCoverageAreas } from "../../action/shop";
import { createOrder,fetchODDTime, getPrice } from "../../action/parcel";
import { Card, Container, SubHeaderText } from "../../styles/globalStyle";
import {notificationList} from "../../action/support";
const { Option } = Select;
const CreateParcel = ({
  status,
  close,
  refresh,
  getCoverageAreas,
  createOrder,
  fetchODDTime,
  location,
  getPrice,
}) => {
  const [coverageArea, setCoverageArea] = useState(null);
  const [lan, setLan] = useState("english");

  const [city, setCity] = useState('');
  const [subCity, setSubCity] = useState('');
  const [thana, setThana] = useState('');
  const [area, setArea] = useState('');

  const [subCityArr, setSubCityArr] = useState(null);
  const [thanaArr, setThanaArr] = useState(null);
  const [areaArr, setAreaArr] = useState(null);

  const [odd, setOdd] = useState(null);
  const [oddTime, setOddTime] = useState(null);
  const [currentTime, setCurrentTime] = useState(moment().format("HH:mm"));
  const [validTime, setValidTime] = useState(false);
  const [storeId, setStoreId] = useState("");
  const [storeName, setStoreName] = useState("");
  const [areaCode, setAreaCode] = useState(null);
  const [amountToCollect, setAmountToCollectValue] = useState(null);
  const [weightValue, setWeight] = useState(null);
  const [deliveryType, setDeliveryType] = useState(null);
  const [pricing, setPricing] = useState(null);


  const [form] = Form.useForm();

  useEffect(async () => {
    window.scrollTo(0, 0);
    const search = location.search;
    const shop_id = new URLSearchParams(search).get("id");
    const shop_name = new URLSearchParams(search).get("name");
    const res = await getCoverageAreas();
    setCoverageArea(res);
    setStoreId(shop_id);
    setStoreName(shop_name);
    setLan(
      localStorage.getItem("lan") ? localStorage.getItem("lan") : "english"
    );
    setCurrentTime(moment().format("hh:mm"));
    let present = moment();
    const time = await fetchODDTime();
    setOddTime(time);
    setOdd(time?.odd)
    if (
      moment(time?.start_time, "hh:mm").isSameOrBefore(
        moment(present, "hh:mm")
      ) &&
      moment(time?.end_time, "hh:mm").isSameOrAfter(moment(present, "hh:mm"))
    ) {
      setValidTime(true);
    }
  }, []);

  const handleCity = async (event) =>{
    const cityCode = event; 
  
    const subCity = [...coverageArea.city[cityCode -1].sub_city];
    setCity(cityCode)
    setSubCityArr([...subCity])
    form.setFieldsValue({
      subCity: '',
      thana: '',
      area: ''
    })
  }
  const handleSubcity = async (event) =>{
    const subCode =  event;
    const thana = [...subCityArr[ subCode -1].thana];
    setSubCity(subCode)
    setThanaArr([...thana])
    form.setFieldsValue({
      thana: '',
      area: ''
    })
  }

  const handleThana = (event) =>{
      setThana(null)
      const thanaCode = event;
    
      const area = [...thanaArr[thanaCode - 1].area]
      setThana(thanaCode)
      setAreaArr([...area])
      form.setFieldsValue({
        area: ''
      })
  }

  const handleArea = async (event) =>{
      const areaCode = event;
      setArea(areaCode)
      
      const price = await getPrice(storeId,amountToCollect ,weightValue,city+'-'+subCity+'-'+thana+'-'+event, deliveryType)
      setPricing(price)
  }

  const onFinish = async (values) => {
    const res = await createOrder(pricing ,storeId, values, city+'-'+subCity+'-'+thana+'-'+area );
    if (res) {
      window.scrollTo(0, 0);
      form.resetFields()
      setPricing(null)
    }
  };

  const calculation = async (val,name) => {
    if(name==='amount_to_collect'){
      setAmountToCollectValue(val)
      const res = await getPrice(
        storeId,
        val,
        weightValue,
        city+'-'+subCity+'-'+thana+'-'+area,
        deliveryType
      );
      setPricing(res)
    }
    if(name==='weight'){
      setWeight(val)
      const res = await getPrice(
        storeId,
        amountToCollect,
        val,
        city+'-'+subCity+'-'+thana+'-'+area,
        deliveryType
      );
      setPricing(res)
    }
    if(name==='delivery_type'){
      setDeliveryType(val)
      const res = await getPrice(
        storeId,
        amountToCollect,
        weightValue,
        area ? city+'-'+subCity+'-'+thana+'-'+area : '1-1-1-1',
        val
      );
      
      if(res != undefined){
        setPricing(res)
      }
      else{
        form.setFieldsValue({
          delivery_type: '',
        })
      }
    }
  };

  const onFinishFailed = (errorInfo) => {};
  const type = [
    { label: "Select One", value: "" },
    { label: "Parcel", value: "Parcel" },
    { label: "Document", value: "Document" },
  ];
  const weight = [
    { label: "Select weight in kg", val: "" },
    { label: "0-0.5 ", value: "500" },
    { label: "0.5-1 ", value: "1000" },
    { label: "1-2 ", value: "2000" },
    { label: "2-3 ", value: "3000" },
    { label: "3-4 ", value: "4000" },
    { label: "4-5 ", value: "5000" },
    { label: "5-6 ", value: "6000" },
    { label: "6-7 ", value: "7000" },
    { label: "7-8 ", value: "8000" },
    { label: "8-9 ", value: "9000" },
    { label: "9-10 ", value: "10000" },
    { label: "10-11 ", value: "11000" },
    { label: "11-12 ", value: "12000" },
    { label: "12-13 ", value: "13000" },
    { label: "13-14 ", value: "14000" },
    { label: "14-15 ", value: "15000" },
  ];
  const ODD_time = [
    { label: "Select One", value: "" },
    { label: "Normal Delivery", value: "ND" },
    { label: "Same Day Delivery", value: "ODD" },
  ];
  const normal_time = [
    { label: "Select One", value: "" },
    { label: "Normal Delivery", value: "ND" },
  ];

  const mainForm = () => {
    return <Form
    layout="vertical"
    // hideRequiredMark
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    form={form}
    style={{backgroundColor: '#FFF', padding: '10px 15px', borderRadius: '10px',boxShadow: '1px 1px 4px 1px rgba(0,0,0,0.2)'}}
  >

    <Row gutter={16}>
      <Col xs={24}>
        <Divider>
          {lan === "bangla" ? "ধরণ এবং আইডি" : "Type & ID"}
        </Divider>
      </Col>
      <Col xs={24} sm={8}>
        <Form.Item
          name="product_type"
          label={lan === "bangla" ? " পার্সেলের ধরণ" : "Parcel Type"}
          rules={[
            {
              required: true,
              message:
                lan === "bangla"
                  ? "অত্যাবশ্যকীয়"
                  : "This fiels is required",
            },
          ]}
        >
          <Select
            size="middle"
            placeholder={
              lan === "bangla" ? "পার্সেলের ধরণ" : "Parcel Type"
            }
          >
            {type?.map((item, id) => (
              <Option key={id} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>

      <Col xs={24} sm={8}>
        <Form.Item
          name="merchant_order_id"
          label={
            lan === "bangla"
              ? "পার্সেলের রেফারেন্স নম্বার"
              : "Parcel Reference Number"
          }
          rules={[
            {
              required: true,
              message:
                lan === "bangla"
                  ? "অত্যাবশ্যকীয়"
                  : "This fiels is required",
            },
          ]}
        >
          <Input size="middle" />
        </Form.Item>
      </Col>
      {odd == 'true' ? (
        <Col xs={24} sm={8}>
          <Form.Item
            name="delivery_type"
            label={
              lan === "bangla" ? " ডেলিভারির ধরণ " : "Delivery Type"
            }
            rules={[
              {
                required: true,
                message:
                  lan === "bangla"
                    ? "অত্যাবশ্যকীয়"
                    : "This fiels is required",
              },
            ]}
          >
            <Select
              size="middle"
              placeholder={
                lan === "bangla" ? "ডেলিভারির ধরণ " : "Delivery Type"
              }
              onChange={(e)=> calculation(e,'delivery_type')}
            >
              {ODD_time?.map((item, id) => (
                <Option key={id} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      ) : (
        <Col xs={24} sm={8}>
          {/* <h5 style={{ color: "#dd4f4f" }}>
                  Same day delivery will be open from{" "}
                  {moment(oddTime?.start_time, ["HH.mm"]).format("hh:mm a")} to{" "}
                  {moment(oddTime?.end_time, ["HH.mm"]).format("hh:mm a")}
              </h5> 
          */}

          <Form.Item
            name="delivery_type"
            label={
              lan === "bangla" ? " ডেলিভারির ধরণ " : "Delivery Type"
            }
            rules={[
              {
                required: true,
                message:
                  lan === "bangla"
                    ? "অত্যাবশ্যকীয়"
                    : "This fiels is required",
              },
            ]}
          >
            <Select
              size="middle"
              placeholder={
                lan === "bangla" ? "ডেলিভারির ধরণ " : "Delivery Type"
              }
              onChange={(e)=> calculation(e,'delivery_type')}
            >
              {normal_time?.map((item, id) => (
                <Option key={id} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      )}
    </Row>

    <Row gutter={16}>
      <Col xs={24}>
        <Divider>
          {lan === "bangla" ? "কাস্টমারের ডিটেইলস" : "Customer Details"}
        </Divider>
      </Col>
      <Col  xs={24} sm={8}>
        <Form.Item
          name="recipient_name"
          label={
            lan === "bangla" ? "কাস্টমারের নাম" : "Customer's Name"
          }
          rules={[
            { required: true, message: "This fiels is required" },
          ]}
        >
          <Input size="middle" />
        </Form.Item>
      </Col>
      <Col  xs={24} sm={8}>
        <Form.Item
          name="recipient_phone"
          label={
            lan === "bangla"
              ? "কাস্টমারের মোবাইল নাম্বার"
              : "Customer's Mobile Number"
          }
          rules={[
            {
              required: true,
              message:
                lan === "bangla"
                  ? "অত্যাবশ্যকীয়"
                  : "This fiels is required",
            },
          ]}
        >
          <Input size="middle" />
        </Form.Item>
      </Col>
      <Col xs={24}>
        <Form.Item
          name="recipient_address"
          label={
            lan === "bangla" ? "কাস্টমারের এড্রেস" : "Customer's Addess"
          }
          rules={[
            {
              required: true,
              message:
                lan === "bangla"
                  ? "অত্যাবশ্যকীয়"
                  : "This fiels is required",
            },
          ]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
      </Col>
    </Row>

    <Row gutter={16}>
      <Col xs={24}>
        <Divider>
          {lan === "bangla" ? "এরিয়া ডিটেইলস" : "Area Details"}
        </Divider>
      </Col>
      {/* ================ City =============== */}
      <Col  xs={24} sm={6}>
        <Form.Item
          name="city"
          label={lan === "bangla" ? " কাস্টমারের সিটি" : "Select City"}
          rules={[
            {
              required: true,
              message:
                lan === "bangla"
                  ? "অত্যাবশ্যকীয়"
                  : "This fiels is required",
            },
          ]}
        >
          <Select
            showSearch
            optionFilterProp="children"
            size="middle"
            placeholder={
              lan === "bangla"
                ? " সিটি সিলেক্ট করুন"
                : "Select one city"
            }
            onChange={(e) => handleCity(e)}
          >
            
            {coverageArea !== null
              ? coverageArea?.city?.map((item, id) => (
                  <Option key={id} value={item.id}>
                    {item.name}
                  </Option>
                ))
              : null}
          </Select>
        </Form.Item>
      </Col>

      {/* ================ Sub City ============ */}
      {city ? (
        <Col  xs={24} sm={6}>
          <Form.Item
            name="subCity"
            showSearch
            optionFilterProp="children"
            label={
              lan === "bangla"
                ? " কাস্টমারের সাব-সিটি"
                : "Select Subcity"
            }
            rules={[
              {
                required: true,
                message:
                  lan === "bangla"
                    ? "অত্যাবশ্যকীয়"
                    : "This fiels is required",
              },
            ]}
          >
            <Select
              size="middle"
              placeholder={
                lan === "bangla"
                  ? "সাব-সিটি সিলেক্ট করুন"
                  : "Select subcity"
              }
              onChange={(e) => handleSubcity(e)}
            >
              
              {subCityArr !== null
                ? subCityArr?.map((item, id) => (
                    <Option key={id} value={item.id}>
                      {item.name}
                    </Option>
                  ))
                : null}
            </Select>
          </Form.Item>
        </Col>
      ) : null}
      
      {/* ================ Thana ============ */}
      {subCity ? (
        <Col  xs={24} sm={6}>
          <Form.Item
            name="thana"
            showSearch
            optionFilterProp="children"
            label={
              lan === "bangla"
                ? " কাস্টমারের থানা"
                : "Select thana"
            }
            rules={[
              {
                required: true,
                message:
                  lan === "bangla"
                    ? "অত্যাবশ্যকীয়"
                    : "This fiels is required",
              },
            ]}
          >
            <Select
              size="middle"
              placeholder={
                lan === "bangla"
                  ? " থানা সিলেক্ট করুন"
                  : "Select thana"
              }
              onChange={(e) => handleThana(e)}
            >
              
              {thanaArr !== null
                ? thanaArr?.map((item, id) => (
                    <Option key={id} value={item.id}>
                      {item.name}
                    </Option>
                  ))
                : null}
            </Select>
          </Form.Item>
        </Col>
      ) : null}
      
      {/* ================ Area ============ */}
      {thana  ? (
        <Col  xs={24} sm={6}>
          <Form.Item
            name="area"
            showSearch
            optionFilterProp="children"
            label={
              lan === "bangla"
                ? " কাস্টমারের এরিয়া"
                : "Select Area"
            }
            rules={[
              {
                required: true,
                message:
                  lan === "bangla"
                    ? "অত্যাবশ্যকীয়"
                    : "This fiels is required",
              },
            ]}
          >
            <Select
              size="middle"
              placeholder={
                lan === "bangla"
                  ? " এরিয়া সিলেক্ট করুন"
                  : "Select area"
              }
              onChange={(e) => handleArea(e)}
            >
              
              {areaArr !== null
                ? areaArr?.map((item, id) => (
                    <Option key={id} value={item.id}>
                      {item.name}
                    </Option>
                  ))
                : null}
            </Select>
          </Form.Item>
        </Col>
      ) : null}

    </Row>
    <Row gutter={16}>
      <Col span={24}>
        <Divider>
          {lan === "bangla" ? "পার্সেলের ডিটেইলস" : "Product Details"}
        </Divider>
      </Col>
      <Col  xs={24} sm={8}>
        <Form.Item
          name="item_actual_price"
          label={
            lan === "bangla"
              ? "পার্সেলের আসল মূল্য"
              : "Item actual price"
          }
          rules={[
            {
              required: true,
              message:
                lan === "bangla"
                  ? "অত্যাবশ্যকীয়"
                  : "This fiels is required",
            },
          ]}
        >
          <Input size="middle" />
        </Form.Item>
      </Col>
      <Col  xs={24} sm={8}>
        <Form.Item
          name="amount_to_collect"
          label={
            lan === "bangla"
              ? "কালেক্ট করার পরিমাণ"
              : "Amount to collect"
          }
          onChange ={(e) => {calculation(e.target.value,'amount_to_collect')}}
          rules={[
            {
              required: true,
              message:
                lan === "bangla"
                  ? "অত্যাবশ্যকীয়"
                  : "This fiels is required",
            },
          ]}
        >
          {area !== '' ?
          <Input size="middle" />
          :
          <Input disabled size="middle" placeholder={lan === "bangla" ? "এরিয়া ডিলেট পূরণ করুন" : "Select area first"} />
          }
        </Form.Item>
      </Col>
      <Col  xs={24} sm={8}>
        <Form.Item
          name="quantity"
          label={lan === "bangla" ? "পণ্যের পরিমাণ" : "Quantity"}
          rules={[
            {
              required: true,
              message:
                lan === "bangla"
                  ? "অত্যাবশ্যকীয়"
                  : "This fiels is required",
            },
          ]}
        >
          <Input size="middle" />
        </Form.Item>
      </Col>
      <Col  xs={24} sm={8}>
        <Form.Item
          name="weight"
          label={lan === "bangla" ? "পণ্যের ওজন" : "Product Weight (kg)"}
          rules={[
            {
              required: true,
              message:
                lan === "bangla"
                  ? "অত্যাবশ্যকীয়"
                  : "This fiels is required",
            },
          ]}
        >
          {area !== '' ?
          <Select
            size="middle"
            placeholder={
              lan === "bangla"
                ? " ওজন সিলেক্ট করুন"
                : "Select one"
            }
            onChange = {(e) => calculation(e,'weight')}
          >
            {weight !== null
              ? weight?.map((item, id) => (
                  <Option key={id} value={item.value}>
                    {item.label}
                  </Option>
                ))
              : null}
          </Select>
          :
          <Input type="text" disabled  placeholder={lan === "bangla" ? "এরিয়া ডিটেইল পূরণ করুন" : "Select area first"}/>
              }
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          name="item_description"
          label={lan === "bangla" ? "বিবরণ" : "Description"}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          name="note"
          label={lan === "bangla" ? "নোট" : "Note"}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col span={24}>&nbsp;</Col>
      <Col span={24}>
        <Form.Item style={{ textAlign: "center" }}>
          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form.Item>
      </Col>
    </Row>

  </Form>
  }
  const calculator = () => {
    return(
      <Row gutter={24} className="fixed-bar">
 
 <      Col span={24}>
          <Card style={{minHeight: 'auto'}}>
            <SubHeaderText style={{textAlign: 'left', padding: '10px'}}>{lan === 'bangla' ? 'ক্যাল্কুলেশন' : 'Calculation'}</SubHeaderText>
            
            <Row gutter={16} style={{padding: '0px 50px'}}>
                <Col xs={12} ><p>{lan === 'bangla' ? 'কালেক্ট করার পরিমাণ': 'Amount to collect:'}</p></Col><Col style={{textAlign: 'right', color: 'green'}} xs={12} >{pricing?.amount_to_collect} &#2547;</Col>
                <Col xs={12} ><p>{lan === 'bangla' ? 'ডেলিভারী চার্জ': 'Total delivery charge:'}</p></Col><Col style={{textAlign: 'right', color: 'red'}} xs={12} >-{pricing?.delivery_charge} &#2547;</Col>
                <Col xs={12} ><p>{lan === 'bangla' ? 'সিওডি চার্জ': 'COD charge:'}</p></Col><Col style={{textAlign: 'right', color: 'red'}} xs={12} >-{pricing?.cod} &#2547;</Col>
                <Col xs={24} ><hr /></Col>
                <Col xs={12} ><p>{lan === 'bangla' ? 'পেএবল এমাউন্ট': 'Total payable amount:'}</p></Col><Col style={{textAlign: 'right', fontWeight: '600'}} xs={12} > {pricing?.payable_amount} &#2547;</Col>
            </Row>
          </Card>
        </Col>
        <Col span={24}>
          {/* <Card style={{minHeight: 'auto'}}>
            <SubHeaderText style={{textAlign: 'left', padding: '10px'}}>{lan === 'bangla' ? '** জরুরী বার্তা **' : '** Important **'}</SubHeaderText>
            
            <Row gutter={16} style={{paddingLeft: '50px'}}>
              
              <Col xs={24} >
                <h3 style={{fontWeight: 'bold'}} >Header</h3>
                <p>Detail</p>
              </Col>
              </Row>
          </Card> */}
        </Col>
        
    </Row>
    )
  }
  return (
    <Container>
      <h2 style={{ padding: "20px", fontWeight: "600", textAlign: "center" }}>
        {lan === "bangla" ? "পার্সেল তৈরি করুন" : "Create a new parcel"}
      </h2>
      <Row gutter={24}>
          <Col xs={24} sm={14}>
            {mainForm()}
          </Col>
          <Col xs={1} sm={1}> </Col>
          <Col  xs={24}  sm={8} >
            {calculator()}
          </Col>
        </Row>
    </Container>
  );
};

export default connect(null, { getCoverageAreas, getPrice, createOrder,fetchODDTime })(
  CreateParcel
);
