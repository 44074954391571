import React, { Component, useEffect, useState } from 'react';
import { Table, Tag, Space, DatePicker, Modal, Button, Input, Row, Col, Select, Tooltip, Popconfirm, Pagination, Skeleton, Form, PageHeader } from 'antd';
import AddPaymentMethod from './addPaymentMethod';

import {CameraOutlined, CheckCircleOutlined, ClearOutlined, DeleteOutlined, EditOutlined, EyeFilled, PlusSquareOutlined, StopOutlined} from '@ant-design/icons'
// import AddProduct from './addProduct';
import { connect } from 'react-redux';
import {fetchShops} from '../../action/shop';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
// import EditProduct from './editProduct';
// import UpdateImage from './updateImage';
import history from '../../history';
import { Container, FlexContainer, HeaderText } from '../../styles/globalStyle';
import CreateStore from './createStore';
import EditStore from './editStore';
import {notificationCount} from "../../action/support";
const StoreList = ({fetchShops,notificationCount}) => {
    const [lan, setLan] = useState('english');
    const [shop, setShop] = useState([]);
    const [createForm, setCreateForm] = useState(false);
    const [editForm, setEditForm] = useState(false);
    const [shopId, setShopId] = useState(null);
    const [formShow, setFormShow] = useState(false);
    useEffect( async () => {
        window.scrollTo(0, 0);
        notificationCount()
        setLan(localStorage.getItem('lan') ? localStorage.getItem('lan') : 'english')
        const res = await fetchShops()
        setShop(res)
    }, []);

    const onCreate = () => {
      setCreateForm(true)
    }
    const onClose = () => {
      setCreateForm(false)
    }

    const handleRefresh = async () => {
      const res = await fetchShops()
        setShop(res)
    }

    const createData = (shop_id, name,address,status, account_balance, action) => {
        return {shop_id, name,address,status, account_balance, action};
    };
    const columns = [
        {
          title: 'Shop ID',
          dataIndex: 'shop_id',
          key: 'shop_id',
          width: '10%',
        },
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          width: '20%',
        },
        {
          title: 'Address',
          dataIndex: 'address',
          key: 'address',
          width: '25%',
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
        },
        {
          title: 'Account Balance',
          dataIndex: 'account_balance',
          key: 'account_balance',
        },
        {
          title: 'Action',
          dataIndex: 'action',
          key: 'action',
        }
      ];
    const rows = [
      shop
        ? shop.map((data) => createData(
          data?.shop_id,
          data?.name, 
          data?.address,
          data.active === true ?
              <Tag color="#87d068">Active</Tag> 
            : 
              <Tag color="#df2f2f">Inactive</Tag>
              ,
          data?.account_balance,
          
          <>
            
            <Button size="small" type="primary" icon={<EditOutlined  />} onClick={()=> {setShopId(data?.shop_id); setEditForm(true)}}>Edit</Button> &nbsp;
            <Button size="small" type="primary" icon={<EyeFilled  />} onClick={()=> history.push(`/store-details?shop_id=${data?.shop_id}`)}>View</Button> &nbsp;
            <Button size="small" type="primary" icon={<EditOutlined  />} onClick={()=> {setShopId(data?.shop_id);setFormShow(true)}}>Edit Payment Method</Button> &nbsp;
          {/*{data.type === 'NO' ? data.state === 'Pending' ?
          <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={()=>this.handleCancel(data?.order_id)}><Button size="small" type="danger" icon={<DeleteOutlined  />} >Cancel Order</Button></Popconfirm>
          :null : null} */}
          
          </>
          ))
        : "",
    ];
    
    const pageHeader = () => (

      <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="Shop List"
      extra={
        <>
          <Button
            type="primary"
            icon={<PlusSquareOutlined />}
            size="middle"
            onClick={onCreate}
          >
            {lan === 'bangla' ? 'নতুন স্টোর যুক্ত করুন' : 'Add A New Store'}
          </Button>
           
          {shop.length !== 0 ? (
            <Tag
              style={{
                fontSize: "14px",
                padding: "5px",
                float: "right",
              }}
              color="#87d068"
            >
              {lan == "bangla"
                ? `${shop.length} টি ফলাফল পাওয়া গিয়েছে`
                : `Total ${shop.length} data found`}
            </Tag>
          ) : (
            shop.length !== '' ?
            <Tag
              style={{
                fontSize: "14px",
                padding: "5px",
                float: "right",
              }}
              color="#ce821f"
            >
              {lan == "bangla"
                ? `কোনো ফলাফল পাওয়া যায় নি`
                : `No data found`}
            </Tag>
            :
            null
          )}
        </>
      }
    ></PageHeader>
    )
    const noStore = () => (
      shop.length === 0 ?
      <FlexContainer style={{ height: "50vh" }}>
        <div
          style={{
            alignSelf: "center",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: "50%",
          }}
        >
          <h1
            style={{
              textAlign: "center",
              alignSelf: "center",
              fontWeight: "600",
            }}
          >
            {lan === "bangla"
              ? "নতুন স্টোর তৈরি করুন"
              : "Create Your new store"}
          </h1>
          <p style={{ textAlign: "center", color: "gray" }}>
            {lan === "bangla"
              ? "আপনার কোনো স্টোর এখনো নেই, পার্সেল এড কয়ার জন্য স্টোর তৈরি করুন"
              : "You don't have store. Add store to create parcel"}
          </p>
          <Button
            type="primary"
            style={{ textAlign: "center" }}
            onClick={onCreate}
          >
            {lan === "bangla" ? "এড স্টোর" : "Add Store"}
          </Button>
        </div>
      </FlexContainer>
      :
      null
    )
    const dataSection = () => (
      shop.length > 0 ?
        <Table columns={columns}  scroll={{ x: 10 }} dataSource={rows[0]} pagination={false} />
      :null
    )
    const modals = () => (
      <>
      
        <CreateStore status={createForm} close={onClose} refresh = {handleRefresh} re={true} />
        {editForm ? 
          <EditStore  shop_id={shopId} status={editForm} close={() => setEditForm(false)} refresh = {handleRefresh} re={true} />
        : null}
        <AddPaymentMethod shop_id={shopId} refresh={handleRefresh} status={formShow} close = {() => setFormShow(false)} />
      </>
    )
    return ( 
        
      <Container>
          {pageHeader()}
          {dataSection()}
          {noStore()}
          {modals()}
      </Container>
     );
}
 
export default connect(null,{fetchShops,notificationCount})(StoreList);

 